'use strict';

Gri.module({
  name: 'colors-alpha',
  ieVersion: null,
  $el: $('.colors-alpha'),
  container: '.colors-alpha',
  fn: function () {
    this.$el.find(".color").click(function () {
      var imgSrc = $(this).find(".box").data("img");
      $(".slide-img").attr("src",imgSrc);
    })
  }
});
